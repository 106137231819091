import { last } from "lodash";
import { useEffect, useRef, useState } from "react";

export const checkIsHlsMedia = (url) => {
  if (!url) return false;
  const extension = last(url.split("."));
  return extension === "m3u8";
};

export const useHlsMedia = ({ autoPlay = false, url, ref: playerRef }) => {
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [isSupported, setIsSupported] = useState(true);
  const [isHlsMedia, setIsHlsMedia] = useState(checkIsHlsMedia(url));
  const [error, setError] = useState(null);
  const Hls = useRef();
  const hls = useRef();

  useEffect(() => {
    Hls.current = window.Hls;
    hls.current = new window.Hls();
  }, []);

  const cleanUpHls = () => {
    hls.current?.destroy?.();
  };

  function handleHlsError(event, data) {
    if (typeof Hls.current === "undefined") return;
    switch (data.type) {
      case Hls.current.ErrorTypes.NETWORK_ERROR:
        // try to recover network error
        // hls.current.startLoad();
        setRetryAttempts((n) => {
          if (n < 25) {
            setTimeout(() => hls.current.recoverMediaError(), n * 0.5 * 1000);
          }
          return n + 1;
        });
        break;
      case Hls.current.ErrorTypes.MEDIA_ERROR:
        hls.current.recoverMediaError();
        break;
      default:
        // cannot recover
        setError(true);
        hls.current.destroy();
        break;
    }
  }

  useEffect(() => {
    setIsHlsMedia(checkIsHlsMedia(url));
  }, [url]);

  useEffect(() => {
    if (typeof Hls.current === "undefined") return;
    const isHlsMedia = checkIsHlsMedia(url);
    if (!playerRef.current) {
      return;
    }
    if (!url) {
      return;
    }
    if (!isHlsMedia) {
      return;
    }
    if (!Hls.current.isSupported()) {
      return setIsSupported(false);
    }
    setIsSupported(true);

    hls.current.attachMedia(playerRef.current);

    hls.current.on(Hls.current.Events.MEDIA_ATTACHED, function () {
      hls.current.loadSource(url);
    });

    hls.current.on(Hls.current.Events.MANIFEST_PARSED, function (event, data) {
      setError(false);
      if (autoPlay) {
        playerRef.current.play();
      }
    });

    hls?.on?.(Hls.current.Events.ERROR, handleHlsError);

    return () => {
      hls.current.destroy();
    };
  }, [playerRef, url, autoPlay]);

  return {
    error,
    playerRef,
    isSupported,
    isHlsMedia,
    cleanUpHls,
  };
};
