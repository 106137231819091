import { forwardRef, useRef } from "react";
import { checkIsHlsMedia, useHlsMedia } from "~/lib/use-hls-media";

export const VideoPlayerWrapper = ({ children }) => {
  return (
    <div
      className="absolute inset-0 flex-center bg-storm-900"
      style={{ width: 440 }}
    >
      {children}
    </div>
  );
};

const HlsVideoPlayer = ({
  url,
  playerRef,
  autoPlay = true,
  loop = true,
  controls = false,
  ...rest
}) => {
  useHlsMedia({
    ref: playerRef,
    url,
  });

  return (
    <video
      ref={playerRef}
      src={url}
      width="440"
      height="auto"
      autoPlay={autoPlay}
      controls={controls}
      loop={loop}
      {...rest}
    />
  );
};

export const VideoPlayer = forwardRef(
  (
    { autoPlay = true, loop = true, controls = false, url, ...rest },
    forwarededRef
  ) => {
    let ref = useRef();
    if (forwarededRef) {
      ref = forwarededRef;
    }

    const isHlsMedia = checkIsHlsMedia(url);

    if (isHlsMedia) {
      return (
        <HlsVideoPlayer
          loop={loop}
          url={url}
          playerRef={ref}
          autoPlay={autoPlay}
          controls={controls}
          {...rest}
        />
      );
    }

    return (
      <video
        loop={loop}
        width="440"
        height="auto"
        autoPlay={autoPlay}
        controls={controls}
        ref={ref}
        {...rest}
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
);
